import { Box, Typography } from "@mui/material";
import { chain, round } from "mathjs";
import moment from "moment-timezone";
import React from "react";
import "../PrintBill.scss";
import startCase from "lodash/startCase";
import { ServiceProvider } from "../../../../interfaces/ServiceProvidersInterface";
import { BillType, DiscountBasedOn, DocumentTypes } from "../../../../interfaces/BillInterfaces";
import { useAppSelector } from "../../../../store/hooks";
import { getSectionStyle } from "../../../Lab/LabPrint/LabPrintFunctions";
import classNames from "../../../../helpers/classNames";
import { scaleFont } from "../../../../components/Print/Print";
import { spFullNameSelector, spFromIdSelector } from "../../../../reducers/serviceProvider";
import hasOwnProperty from "../../../../helpers/object";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { tl, t } from "../../../../components/translate";
import okhatiQR from "../../../../../assets/images/okhatiqr.png";
import capitalizedName from "../../../../helpers/nameCapitalizer";
import { InfoField } from "../BillPrintHelpers";
import {
  getColumnTextStyles,
  showInfoColumn,
  showUnitAfterQuantity
} from "../../../../actions/helpers/billHelper";
import BillPrintSummary from "../../BillPrintSummary";

interface Props {
  billData: BillType;
  SPs: ServiceProvider[];
  relatedSettings: { showReferrer: boolean; isBordered: boolean; headerColor: string };
  clientBalance: number;
  isVatRegistered: boolean;
}

const CorporateBillDescription = ({
  billData,
  SPs,
  relatedSettings,
  clientBalance,
  isVatRegistered
}: Props): JSX.Element | null => {
  const user = useAppSelector((state) => state.userContext.user);

  if (!billData || !billData.document) return null;
  const showInfo = showInfoColumn(billData);

  const billColumns = [
    "sNo",
    "description",
    "info",
    "quantity",
    "perUnit",
    "discountAmt",
    "vatPct",
    "grossTotal"
  ].filter((header) => {
    if (header === "discountAmt") {
      return !(
        billData.document?.settings.discountSettings.discountBasis === DiscountBasedOn.invoice
      );
    }
    if (header === "vatPct") {
      return isVatRegistered;
    }
    if (header === "info") return showInfo;
    return true;
  });

  const showIndividualVAT =
    Object.keys(billData.document.summary.taxAmtWithRates || {}).filter(
      (vatPct) => Number(vatPct) !== 0
    ).length > 1;
  let netTotal;
  if (billData.document.type === DocumentTypes.CREDITNOTE) {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .add(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  } else {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .subtract(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  }

  return (
    <div>
      <div>
        <Box
          display="flex"
          borderTop="1px solid black"
          borderRight={relatedSettings.isBordered ? "1px solid black" : "none"}
          borderBottom={relatedSettings.isBordered ? "1px solid black" : "none"}
          borderLeft={relatedSettings.isBordered ? "1px solid black" : "none"}
          paddingBottom="0.2cm"
          p="0.3cm"
          className={!relatedSettings.isBordered ? "borderBotBlack1" : ""}
          sx={
            relatedSettings.isBordered
              ? getSectionStyle(relatedSettings.headerColor).rawHeaderStyle
              : {}
          }
        >
          {billColumns.map((column, i) => (
            <Typography
              key={column}
              className={classNames({
                grow1: i === 0 || i === 2,
                flex3: i === 1 || (i === 2 && showInfo),
                flex1: i > 2
              })}
              sx={{
                textTransform: "uppercase",
                textAlign: i > 2 ? "center" : "left",
                fontWeight: 600,
                fontSize: scaleFont("0.27cm"),
                whiteSpace: "pre"
              }}
            >
              {tl(`billing.billItem.${column}`)}
            </Typography>
          ))}
        </Box>

        <Box paddingBottom="0.45cm">
          {billData.document.billItems.map((item, index) => (
            <Box
              key={item.sNo}
              display="flex"
              sx={relatedSettings.isBordered ? { pageBreakInside: "avoid" } : {}}
              borderTop={
                relatedSettings.isBordered && index !== 0 ? "0.5px solid lightgrey" : "none"
              }
              borderBottom={
                index === billData.document.billItems.length - 1
                  ? relatedSettings.isBordered && "1px solid black"
                  : relatedSettings.isBordered && "0.5px solid lightgrey"
              }
              className={
                index === billData.document.billItems.length - 1 &&
                !relatedSettings.isBordered &&
                "borderBotBlack1"
              }
            >
              {billColumns.map((column, i) => (
                <Box
                  borderLeft={i === 0 && relatedSettings.isBordered ? "1px solid black" : "none"}
                  borderRight={
                    i === billColumns.length - 1 && relatedSettings.isBordered
                      ? "1px solid black"
                      : "none"
                  }
                  paddingLeft={i === 0 ? "0.3cm" : 0}
                  paddingRight={i === billColumns.length - 1 ? "0.3cm" : 0}
                  key={column}
                  className={classNames({
                    grow1: i === 0 || i === 2,
                    flex3: i === 1 || (i === 2 && showInfo),
                    flex1: i > 2
                  })}
                  paddingTop="0.2cm"
                  paddingBottom="0.2cm"
                >
                  <Typography>
                    <Box sx={getColumnTextStyles({ index: i, column })}>
                      {["perUnit", "grossTotal"].includes(column) && item[column] !== null
                        ? Number(item[column]).toFixed(2)
                        : item[column]}{" "}
                      {showUnitAfterQuantity(column) && item.unit}
                      {column === "description" &&
                        item.serviceProviderId &&
                        `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                    </Box>
                    {column === "description" && (
                      <Box
                        sx={{
                          whiteSpace: "pre-wrap",
                          display: "inline",
                          fontSize: scaleFont("0.23cm")
                        }}
                      >
                        {item.batchInfo?.batchId && `Batch ${item.batchInfo?.batchId}`}
                        {item.batchInfo?.expiryDate &&
                          `, Expiring ${moment(item.batchInfo.expiryDate).format("YYYY/MM/DD")}`}
                      </Box>
                    )}
                    {column === "description" &&
                      item?.subItems?.map((subitem) => (
                        <Box key={subitem.productId} paddingTop="0.05cm" className="blockContent">
                          {`◦ ${subitem.description}`}
                          {hasOwnProperty(subitem, "serviceProviderId") &&
                            subitem.serviceProviderId && (
                              <span style={{ marginLeft: "8px" }}>
                                {`(${spFullNameSelector(
                                  spFromIdSelector(SPs, subitem.serviceProviderId)
                                )})`}
                              </span>
                            )}
                        </Box>
                      ))}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </div>
      <Box
        sx={{
          pageBreakInside: "avoid",
          display: "flex",
          paddingTop: "1cm",
          flexWrap: "wrap"
        }}
      >
        <Box width="50%">
          <Box display="flex" gap={2}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: scaleFont("0.3cm"),
                fontWeight: 600,
                whiteSpace: "pre"
              }}
            >
              {tl("billing.inWords")}
            </Typography>
            <Typography fontSize={scaleFont("0.3cm")}>
              {startCase(`${billData.document.summary.inWords} only`)}
            </Typography>
          </Box>
          {Boolean(billData.document.remarks) && (
            <Box display="flex" gap={2} mt={4}>
              <Typography
                sx={{ textTransform: "uppercase", fontSize: scaleFont("0.3cm"), fontWeight: 600 }}
              >
                {tl("billing.remarks")}
              </Typography>
              <Typography fontWeight={400} fontSize={scaleFont("0.3cm")}>
                {billData.document.remarks}
              </Typography>
            </Box>
          )}
        </Box>
        <Box width="50%">
          <Box paddingBottom="0.6cm" width="100%" textAlign="right">
            <BillPrintSummary
              totalPerUnitTimesQty={billData.document.summary.totalPerUnitTimesQty}
              discount={billData.document.summary.discount}
              showIndividualVAT={showIndividualVAT}
              taxAmtWithRates={billData.document.summary.taxAmtWithRates}
              taxAmount={billData.document.summary.taxAmount}
              netTotal={netTotal}
            />
          </Box>
          <div>
            <Box gap={2} alignItems="center" justifyContent="flex-end" display="flex">
              <Box
                fontSize={scaleFont("0.3cm")}
                fontWeight={600}
                style={{ textTransform: "uppercase" }}
              >
                {tl("billing.grandTotal")}
              </Box>
              <Box fontWeight={500} fontSize={scaleFont("0.35cm")}>
                {rupeeDisplay(billData.document.summary.totalAmount.toFixed(2))}
              </Box>
            </Box>
            {clientBalance > 0 && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="flex-end"
                marginTop="0.2cm"
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    align: "right",
                    fontSize: scaleFont("0.3cm"),
                    fontWeight: 600
                  }}
                >
                  {t("billing.advanceRemaining")}
                </Typography>
                <Typography fontWeight={500} fontSize={scaleFont("0.35cm")}>
                  {rupeeDisplay(clientBalance.toFixed(2))}
                </Typography>
              </Box>
            )}
            {billData.paymentInfo?.paidAmount > 0 && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="flex-end"
                marginTop="0.1cm"
              >
                <Box
                  sx={{ textTransform: "uppercase", fontSize: scaleFont("0.3cm"), fontWeight: 600 }}
                >
                  {t("reports.paidAmount")}
                </Box>
                <Typography fontWeight={500} fontSize={scaleFont("0.35cm")}>
                  {round(billData.paymentInfo?.paidAmount, 2).toFixed(2)}
                </Typography>
              </Box>
            )}
            {!billData.paymentInfo?.paid && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="flex-end"
                marginTop="0.1cm"
              >
                <Box
                  sx={{ textTransform: "uppercase", fontSize: scaleFont("0.3cm"), fontWeight: 600 }}
                >
                  {t("reports.dueAmount")}
                </Box>
                <Typography fontWeight={500} fontSize={scaleFont("0.35cm")}>
                  {round(
                    chain(billData.summary?.totalAmount || 0)
                      .subtract(billData.paymentInfo?.paidAmount || 0)
                      .done(),
                    2
                  ).toFixed(2)}
                </Typography>
              </Box>
            )}
          </div>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", pt: 4 }}
      >
        {/* authorised by section */}
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <Typography
            component="span"
            gutterBottom
            sx={{ textTransform: "uppercase", fontSize: scaleFont("0.3cm"), fontWeight: 600 }}
          >
            Authorized by
          </Typography>
          <Typography fontWeight="400" fontSize={scaleFont("0.33cm")} component="span">
            {capitalizedName(billData.document.enteredBy.name)}
          </Typography>
          <Box sx={{ height: "180px" }}>
            {billData.document.enteredBy?.signature && (
              <img
                src={billData.document.enteredBy.signature}
                alt=""
                width="180px"
                style={{ objectFit: "contain" }}
              />
            )}
          </Box>
        </Box>
        {/* printed on section */}
        {user && (
          <Box display="flex" justifyContent="flex-end" flexDirection="column">
            <InfoField
              label={t("billing.printedDateTime")}
              data={
                <>
                  {moment().format("YYYY/MM/DD")} {moment().format("h:mm a")}
                </>
              }
              labelStyle={{ fontSize: scaleFont("0.3cm") }}
              valueStyle={{ fontSize: scaleFont("0.3cm") }}
            />
            {billData?.printCount > 1 && (
              <InfoField
                label={t("billing.rePrintUser")}
                data={<>{capitalizedName(`${user?.firstName} ${user?.lastName}`)}</>}
                labelStyle={{ fontSize: scaleFont("0.3cm") }}
                valueStyle={{ fontSize: scaleFont("0.3cm") }}
              />
            )}
          </Box>
        )}
      </Box>
      {!billData.paymentInfo?.paid && (
        <Box mt={4}>
          <Typography fontWeight={600} gutterBottom>
            SCAN TO PAY
          </Typography>
          <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
            <img src={okhatiQR} alt="" width={100} height={100} />
            <div>
              <Typography>Bank details for payment</Typography>
              <Typography>NEPALLAND IT SOLUTION PVT LTD</Typography>
              <Typography>Prabhu Bank Ltd. </Typography>
              <Typography>Swayambhu branch</Typography>
              <Typography>0400160132300015</Typography>
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default CorporateBillDescription;
