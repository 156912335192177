import produce from "immer";
import { cloneDeep, has, omit } from "lodash";
import { abs, chain, round } from "mathjs";
import * as moment from "moment";
import * as React from "react";
import { convertBStoAD } from "../../components/Calendar/functions/calendarFunctions";
import { paymentOptions, paymentOptionsEnum } from "../../containers/Billing/Editor/BillSummary";
import { SettingsType } from "../../containers/ResourceCentre/Settings";
import hasOwnProperty from "../../helpers/object";
import { numberToWords } from "../../helpers/rupee";
import { TrackingNumType } from "../../interfaces/Accounts";
import {
  BatchInfoType,
  BillDocumentType,
  BillItemFieldTypes,
  BillItemSource,
  BillItemType,
  BillSummaryType,
  BillType,
  CurrentBillVersion,
  DiscountBasedOn,
  DiscountTypes,
  DocumentTypes,
  StockUnitTypes,
  VisitType
} from "../../interfaces/BillInterfaces";
import { Client } from "../../interfaces/ClientInterface";
import { ProductInterface } from "../../interfaces/ProductInterface";
import { StockProducts } from "../../interfaces/StockInterfaces";
import { UserContext } from "../../interfaces/UserContext";
import { ReverseMap } from "../../helpers/types";
import { scaleFont } from "../../components/Print/Print";
import { MEDICAL_SUB_PRODUCT, SCHEME_TYPE } from "../../../map/ssf";
import { SSF_MEDICINE_CODE } from "../../models/ssf";

export const defaultDiscountSettings: { discountBasis; discountType } = {
  discountBasis: DiscountBasedOn.invoice,
  discountType: DiscountTypes.percentage
};

export const isBillCreatedBefore24HoursAgo = (bill: BillType): boolean =>
  moment(bill.created_at).isBefore(moment().subtract(24, "hours"));

export const isOldFormatBill = (billVersion = ""): boolean => {
  const currentVersionInParts = CurrentBillVersion.split(".").map((versionNumber) =>
    Number(versionNumber)
  );
  const oldVersionInParts = billVersion.split(".").map((versionNumber) => Number(versionNumber));
  for (let index = 0; index < currentVersionInParts.length; index += 1) {
    if (currentVersionInParts[index] > oldVersionInParts[index]) return true;
    // eslint-disable-next-line no-continue
    if (currentVersionInParts[index] === oldVersionInParts[index]) continue;
    else return false;
  }
  return false;
};

export enum BillColumns {
  SNO = "sNo",
  DESCRIPTION = "description",
  INFO = "info",
  QUANTITY = "quantity",
  PER_UNIT = "perUnit",
  DISCOUNT_AMT = "discountAmt",
  VAT_PCT = "vatPct",
  GROSS_TOTAL = "grossTotal"
}

export type BillColumnsType = ReverseMap<typeof BillColumns>;

export const getBillColumns = (showInfo: boolean): BillColumns[] => [
  BillColumns.SNO,
  BillColumns.DESCRIPTION,
  ...(showInfo ? [BillColumns.INFO] : []),
  BillColumns.QUANTITY,
  BillColumns.PER_UNIT,
  BillColumns.DISCOUNT_AMT,
  BillColumns.VAT_PCT,
  BillColumns.GROSS_TOTAL
];

export const getTemplateBatchInfo = (): BatchInfoType => ({
  batchId: "",
  quantity: 1,
  expiryDate: null
});

export const getTemplateBillItem = (): Partial<BillItemType> => ({
  sNo: 1,
  delivered: true,
  description: "",
  serviceProviderId: null,
  batchInfo: getTemplateBatchInfo(),
  quantity: 1,
  unit: "pcs",
  perUnit: 0,
  discountPercent: 0,
  discountAmt: 0,
  vatPct: 0,
  vatAmtAfterDiscount: 0,
  vatAmtBeforeDiscount: 0,
  grossTotal: 0,
  serviceProviderRateUnit: "percentage",
  stockUnitType: StockUnitTypes.unit,
  source: BillItemSource.services,
  info: ""
});

export const getDiscountField = (discountSettings: {
  discountBasis;
  discountType;
}): BillItemFieldTypes => {
  if (discountSettings?.discountBasis === DiscountBasedOn.inline) {
    if (discountSettings?.discountType === DiscountTypes.percentage)
      return BillItemFieldTypes.discountPercent;
    return BillItemFieldTypes.discountAmt;
  }
  return null;
};

export enum INSURANCE_TYPE {
  SSF = "ssf"
}

export const createTemplateDraft = (
  userContext: UserContext,
  client: Client = { id: null } as Client,
  rcSettings: SettingsType | Record<string, unknown>,
  isSsfBilling?: boolean
): BillDocumentType => {
  const { billingSettings } = rcSettings || userContext.resourceCentre.settings;
  const isDepartmentSubscribed =
    userContext.resourceCentre?.subscriptions?.features?.department?.subscribed || false;

  return {
    visitType: VisitType.OPD,
    version: CurrentBillVersion,
    type: DocumentTypes.INVOICE,
    insuranceType: isSsfBilling && INSURANCE_TYPE.SSF,
    isDraft: true,
    isInternal: userContext.resourceCentre.settings?.billingSettings?.billingType === "internal",
    title: "bill",
    billNumber: null,
    referredBy: "",
    issueDate: moment().toISOString(),
    dueDate: null,
    remarks: billingSettings?.defaultRemarks || "",
    fiscalYear: "",
    isSsfBilling: false,
    ssf: {
      id: null,
      uuid: null,
      scheme: SCHEME_TYPE.MEDICAL,
      subProduct: MEDICAL_SUB_PRODUCT.OPD,
      balance: {
        medical: { opd: 0, ipd: 0, isActive: false },
        accident: { value: 0, isActive: false }
      }
    },
    summary: {
      taxableAmount: 0,
      taxAmtWithRates: {},
      totalPerUnitTimesQty: 0,
      taxAmount: 0,
      totalAmount: 0,
      inWords: "zero rupees",
      discount: 0,
      discountPct: 0,
      discountType: null
    },
    billItems: [getTemplateBillItem()],
    client,
    resourceCentre: {
      id: userContext.resourceCentre.id,
      panNo: billingSettings?.panNo
    },
    settings: {
      showFields: [
        billingSettings?.extraColumns.delivered && BillItemFieldTypes.delivered,
        BillItemFieldTypes.description,
        billingSettings?.extraColumns?.type && BillItemFieldTypes.type,
        isDepartmentSubscribed &&
          billingSettings?.extraColumns.department &&
          BillItemFieldTypes.department,
        billingSettings?.extraColumns?.quantity && BillItemFieldTypes.quantity,
        billingSettings?.extraColumns?.unit && BillItemFieldTypes.unit,
        BillItemFieldTypes.perUnit,
        BillItemFieldTypes.discountPercent,
        billingSettings?.extraColumns?.info && BillItemFieldTypes.info,
        BillItemFieldTypes.discountAmt,
        billingSettings?.extraColumns.vat && BillItemFieldTypes.VATPercent,
        BillItemFieldTypes.grossTotal
      ].filter((val) => val),
      discountSettings: billingSettings?.discountSettings
        ? billingSettings.discountSettings
        : defaultDiscountSettings,
      remarksCompulsoryForDiscountedBill: Boolean(
        billingSettings?.remarksCompulsoryForDiscountedBill
      ),
      extraColumnSettings: {
        ...billingSettings?.extraColumns
      }
    },
    enteredBy: {
      userId: userContext.userCreds.id
    } as BillDocumentType["enteredBy"],
    paymentInfo: {
      paid: true,
      paymentMethod: "cash",
      paidAmount: null, // initially null. paidAmount is assumed to be
      // equal to summary.totalAmount when paid is true
      tenderAmount: 0,
      changeAmount: 0,
      paymentDistribution: {
        paymentFromBalance: 0,
        additionalPayment: 0
      }
    },
    remindOn: {
      format: "days",
      duration: 0,
      note: ""
    },
    referenceTo: null,
    qrPaymentInfo: userContext.resourceCentre.qrPaymentInfo
  };
};

const findDiscountPct = (discountBasedOn, summary, discount) => {
  if (discountBasedOn === DiscountBasedOn.invoice) {
    return summary.discountPct;
  }
  return round(discount, 2);
};

const findDiscountAmtDisTypePct = (
  discountBasedOn,
  summary,
  discount,
  quantityTimesPerUnitPrice,
  totalExcNonDiscountProduct
) => {
  if (discountBasedOn === DiscountBasedOn.invoice) {
    return (
      round(quantityTimesPerUnitPrice * (summary.discount / totalExcNonDiscountProduct), 2) || 0
    );
  }
  return round(chain(quantityTimesPerUnitPrice).multiply(discount).divide(100).done(), 2);
};

const findDiscountAmtDisTypeAmt = (
  discountBasedOn,
  discount,
  quantityTimesPerUnitPrice,
  totalExcNonDiscountProduct
) => {
  if (discountBasedOn === DiscountBasedOn.invoice) {
    return round(quantityTimesPerUnitPrice * (discount / totalExcNonDiscountProduct), 2);
  }
  return round(discount, 2);
};

const findTotalExcNonDiscountProduct = (draft) => {
  const netTotalExcNonDiscountProduct = draft.billItems.reduce((acc, billItem) => {
    if (!billItem.nonDiscountable) {
      return acc + billItem.perUnit * billItem.quantity;
    }
    return acc;
  }, 0);
  return netTotalExcNonDiscountProduct;
};

const calculateIntermediateBillItemValues = (
  perUnitPrice = 0,
  quantity = 1,
  vatPct = 0,
  discountType = DiscountTypes.percentage,
  discount = 0,
  nonDiscountable = false,
  summary = {},
  discountBasedOn = "",
  totalExcNonDiscountProduct = 0
) => {
  const quantityTimesPerUnitPrice = chain(perUnitPrice).multiply(quantity).done();
  let discountAmt = 0;
  let discountPercent = 0;

  if (discountType === DiscountTypes.percentage) {
    discountAmt = !nonDiscountable
      ? findDiscountAmtDisTypePct(
          discountBasedOn,
          summary,
          discount,
          quantityTimesPerUnitPrice,
          totalExcNonDiscountProduct
        )
      : 0;
    discountPercent = !nonDiscountable ? findDiscountPct(discountBasedOn, summary, discount) : 0;
  } else {
    discountAmt = !nonDiscountable
      ? findDiscountAmtDisTypeAmt(
          discountBasedOn,
          discount,
          quantityTimesPerUnitPrice,
          totalExcNonDiscountProduct
        )
      : 0;
    const dis =
      round(chain(discount).multiply(100).divide(quantityTimesPerUnitPrice).done(), 2) || 0;
    discountPercent = !nonDiscountable ? findDiscountPct(discountBasedOn, summary, dis) : 0;
  }
  const discountedPrice = round(chain(quantityTimesPerUnitPrice).subtract(discountAmt).done(), 2);
  const vatAmtBeforeDiscount = round(
    chain(quantityTimesPerUnitPrice).multiply(vatPct).divide(100).done(),
    2
  );
  const vatAmtAfterDiscount =
    round(chain(discountedPrice).multiply(vatPct).divide(100).done(), 2) || 0;
  const grossTotalWithDiscount =
    round(chain(discountedPrice).add(vatAmtAfterDiscount).done(), 2) || 0;
  const grossTotalWithoutDiscount =
    round(chain(quantityTimesPerUnitPrice).add(vatAmtBeforeDiscount).done(), 2) || 0;
  return {
    discountPercent,
    discountAmt,
    discountedPrice,
    vatAmtBeforeDiscount,
    vatAmtAfterDiscount,
    grossTotalWithDiscount,
    grossTotalWithoutDiscount
  };
};

export function calculateBillItems(draft: BillDocumentType): BillDocumentType {
  let { discount, discountPct } = draft.summary;
  const { discountType } = draft.settings.discountSettings;
  let totalPriceExcVAT = 0;
  let fragmentedDiscountSum = 0;
  if (draft.settings.discountSettings.discountBasis === DiscountBasedOn.invoice) {
    totalPriceExcVAT = draft.billItems.reduce(
      (acc, billItem) =>
        chain(billItem.perUnit || 0)
          .multiply(billItem.quantity || 1)
          .add(acc || 0)
          .done(),
      0
    );
    if (discountType === DiscountTypes.percentage)
      discount = round(
        chain(totalPriceExcVAT)
          .multiply(discountPct || 0)
          .divide(100)
          .done(),
        2
      );
    else discountPct = round(chain(discount).divide(totalPriceExcVAT).multiply(100).done(), 2);
  }
  const billItems = draft.billItems.map((billItem) => {
    const newBillItem = produce(billItem, (draftItem) => {
      draftItem.perUnit = round(draftItem.perUnit || 0, 2);
      draftItem.quantity = draftItem.quantity ?? 1;
      // draftItem.delivered = draft.settings.showFields.includes(BillItemFieldTypes.delivered);
      if (!draft.settings.showFields.includes(BillItemFieldTypes.department)) {
        draftItem.departmentId = null;
      }
      if (draft.settings.discountSettings.discountBasis === DiscountBasedOn.inline) {
        if (draft.settings.discountSettings.discountType === DiscountTypes.percentage) {
          draftItem.discountPercent = draftItem.discountPercent || 0;
          const { discountAmt, vatAmtBeforeDiscount, vatAmtAfterDiscount, grossTotalWithDiscount } =
            calculateIntermediateBillItemValues(
              draftItem.perUnit,
              draftItem.quantity,
              draftItem.vatPct,
              draft.settings.discountSettings.discountType,
              draftItem.discountPercent,
              draftItem.nonDiscountable
            );
          draftItem.discountAmt = discountAmt;
          draftItem.vatAmtAfterDiscount = vatAmtAfterDiscount;
          draftItem.vatAmtBeforeDiscount = vatAmtBeforeDiscount;
          draftItem.grossTotal = grossTotalWithDiscount;
        } else {
          draftItem.discountAmt = draftItem.discountAmt || 0;
          const {
            discountPercent,
            vatAmtBeforeDiscount,
            vatAmtAfterDiscount,
            grossTotalWithDiscount
          } = calculateIntermediateBillItemValues(
            draftItem.perUnit,
            draftItem.quantity,
            draftItem.vatPct,
            draft.settings.discountSettings.discountType,
            draftItem.discountAmt,
            draftItem.nonDiscountable
          );
          draftItem.discountPercent = discountPercent;
          draftItem.vatAmtAfterDiscount = vatAmtAfterDiscount;
          draftItem.vatAmtBeforeDiscount = vatAmtBeforeDiscount;
          draftItem.grossTotal = grossTotalWithDiscount;
        }
      } else {
        // proportinately subtract the discount from individual item &
        // if required randomly re-conciliate on one item
        // (total inc. vat - disount != discounedTotal with vat)
        const totalExcNonDiscountProduct = findTotalExcNonDiscountProduct(draft);
        const {
          discountPercent,
          discountAmt,
          vatAmtBeforeDiscount,
          vatAmtAfterDiscount,
          grossTotalWithoutDiscount
        } = calculateIntermediateBillItemValues(
          draftItem.perUnit,
          draftItem.quantity,
          draftItem.vatPct,
          DiscountTypes.percentage,
          discountPct,
          draftItem.nonDiscountable,
          draft.summary,
          DiscountBasedOn.invoice,
          totalExcNonDiscountProduct
        );
        draftItem.discountPercent = discountPercent;
        draftItem.discountAmt = discountAmt;
        draftItem.vatAmtAfterDiscount = vatAmtAfterDiscount;
        draftItem.vatAmtBeforeDiscount = vatAmtBeforeDiscount;
        draftItem.grossTotal = grossTotalWithoutDiscount;
      }
      // billItem.grossTotal = round(grossTotal, 2);
    });
    fragmentedDiscountSum = chain(fragmentedDiscountSum).add(newBillItem.discountAmt).done();
    return newBillItem;
  });

  // reconciliation for discount on first bill item
  if (draft.settings.discountSettings.discountBasis === DiscountBasedOn.invoice) {
    if (fragmentedDiscountSum !== discount && billItems[0])
      billItems[0] = produce(billItems[0], (draftItem) => {
        const {
          discountPercent,
          discountAmt,
          vatAmtBeforeDiscount,
          vatAmtAfterDiscount,
          grossTotalWithoutDiscount
        } = calculateIntermediateBillItemValues(
          draftItem.perUnit,
          draftItem.quantity,
          draftItem.vatPct,
          DiscountTypes.amount,
          chain(discount).subtract(fragmentedDiscountSum).add(draftItem.discountAmt).done(),
          draftItem.nonDiscountable
        );
        draftItem.discountPercent = discountPercent;
        draftItem.discountAmt = discountAmt;
        draftItem.vatAmtAfterDiscount = vatAmtAfterDiscount;
        draftItem.vatAmtBeforeDiscount = vatAmtBeforeDiscount;
        draftItem.grossTotal = grossTotalWithoutDiscount;
      });
  }
  return { ...draft, billItems };
}

export function calculateSummary(draft: BillDocumentType): BillDocumentType {
  const summary = {
    taxableAmount: 0,
    taxAmtWithRates: {},
    totalPerUnitTimesQty: 0,
    taxAmount: 0,
    totalAmount: 0,
    inWords: "",
    roundOffAmt: draft.summary?.roundOffAmt || "",
    discount: 0,
    discountPct: 0
  };
  let totalBillItemPrice = 0;

  draft.billItems.forEach((billItem) => {
    const quantityTimesPerUnitPrice = chain(billItem.perUnit || 0)
      .multiply(billItem.quantity || 1)
      .done();
    totalBillItemPrice = round(chain(totalBillItemPrice).add(quantityTimesPerUnitPrice).done(), 2);
    if (!Number.isNaN(billItem.vatPct)) {
      summary.taxAmtWithRates[billItem.vatPct] =
        round(
          chain(billItem.vatAmtAfterDiscount)
            .add(summary.taxAmtWithRates[billItem.vatPct] || 0)
            .done(),
          2
        ) || 0;
    }
    summary.taxAmount =
      round(chain(summary.taxAmount).add(billItem.vatAmtAfterDiscount).done(), 2) || 0;
  });
  const netTotalExcNonDiscountProduct = findTotalExcNonDiscountProduct(draft);
  if (draft.settings.discountSettings.discountBasis === DiscountBasedOn.invoice) {
    summary.discountPct =
      draft.settings.discountSettings.discountType === DiscountTypes.percentage
        ? draft.summary.discountPct
        : round(
            chain(draft.summary.discount).divide(netTotalExcNonDiscountProduct).done() * 100,
            2
          ) || 0;
    summary.discount =
      draft.settings.discountSettings.discountType === DiscountTypes.amount
        ? draft.summary.discount
        : round(
            chain(draft.summary.discountPct).divide(100).done() * netTotalExcNonDiscountProduct,
            2
          );
  } else {
    const inlineDiscountSum = draft.billItems.reduce(
      (acc, billItem) => acc + billItem.discountAmt,
      0
    );
    summary.discountPct =
      round(chain(inlineDiscountSum).divide(totalBillItemPrice).done() * 100, 2) || 0;
    summary.discount = inlineDiscountSum;
  }
  summary.totalPerUnitTimesQty = totalBillItemPrice;
  summary.taxableAmount = round(chain(totalBillItemPrice).subtract(summary.discount).done(), 2);
  summary.totalAmount = round(
    chain(totalBillItemPrice)
      .subtract(summary.discount)
      .add(summary.taxAmount)
      .add(summary.roundOffAmt)
      .done(),
    2
  );
  summary.inWords = numberToWords(summary.totalAmount);
  return { ...draft, summary };
}

export function updateShowFields(draft: BillDocumentType): BillDocumentType {
  const { settings } = draft;
  if (!has(settings.extraColumnSettings, "quantity")) {
    settings.extraColumnSettings.quantity = true;
  }
  if (!has(settings.extraColumnSettings, "unit")) {
    settings.extraColumnSettings.unit = true;
  }
  const updatedShowFields = [
    settings.extraColumnSettings?.delivered && BillItemFieldTypes.delivered,
    BillItemFieldTypes.description,
    settings.extraColumnSettings.type && BillItemFieldTypes.type,
    settings.extraColumnSettings.department && BillItemFieldTypes.department,
    settings.extraColumnSettings.quantity && BillItemFieldTypes.quantity,
    settings.extraColumnSettings.unit && BillItemFieldTypes.unit,
    BillItemFieldTypes.perUnit,
    BillItemFieldTypes.discountPercent,
    BillItemFieldTypes.discountAmt,
    settings.extraColumnSettings.vat && BillItemFieldTypes.VATPercent,
    BillItemFieldTypes.grossTotal,
    settings.extraColumnSettings.info && BillItemFieldTypes.info
  ].filter((val) => val);
  return {
    ...draft,
    settings: {
      ...settings,
      showFields: updatedShowFields
    }
  };
}

export function calculateCreditNoteBillItems(
  draft: BillDocumentType,
  originalBill: BillType
): BillDocumentType {
  const originalBillItems = originalBill.document.billItems;
  const billItems = draft.billItems.map((billItem) => {
    const correspondingItem = originalBillItems.find(({ sNo }) => sNo === billItem.sNo);
    const newBillItem = produce(billItem, (draftItem) => {
      draftItem.perUnit = round(draftItem.perUnit || 0, 2);
      if (abs(draftItem.quantity) <= correspondingItem.quantity) {
        draftItem.quantity = -abs(draftItem.quantity);
      } else if (abs(draftItem.quantity) > 1) {
        draftItem.quantity = -correspondingItem.quantity;
      } else {
        draftItem.quantity = -1;
      }
      if (draft.settings.discountSettings.discountBasis === DiscountBasedOn.inline) {
        draftItem.discountAmt = round(
          chain(correspondingItem.discountAmt)
            .divide(correspondingItem.quantity || 0)
            .multiply(abs(draftItem.quantity))
            .done(),
          2
        );
        // always positive as absolute value of quantity is taken
        // for easy calculations in further steps
        const quantityTimesPerUnitPrice = chain(draftItem.perUnit)
          .multiply(abs(draftItem.quantity))
          .done();
        draftItem.vatAmtAfterDiscount = round(
          chain(chain(quantityTimesPerUnitPrice).subtract(draftItem.discountAmt).done())
            .multiply(draftItem.vatPct)
            .divide(100)
            .done(),
          2
        );
        draftItem.grossTotal = -round(
          chain(quantityTimesPerUnitPrice)
            .subtract(draftItem.discountAmt)
            .add(draftItem.vatAmtAfterDiscount)
            .done(),
          2
        );
      }
    });
    return newBillItem;
  });

  return { ...draft, billItems };
}

export function calculateCreditNoteSummary(
  draft: BillDocumentType,
  originalBill: BillType & { summary: BillSummaryType }
): BillDocumentType & { refundInfo: BillDocumentType["refundInfo"] } {
  const refundInfo = {
    previousPaidAmt: originalBill.paymentInfo.paidAmount,
    previousDueAmt: chain(originalBill.summary.totalAmount)
      .subtract(originalBill.paymentInfo.paidAmount)
      .done(),
    previousPaymentMethod:
      originalBill.paymentInfo.paymentMethod === paymentOptionsEnum.balance
        ? paymentOptionsEnum.cash
        : (originalBill.paymentInfo.paymentMethod as paymentOptionsEnum),
    maximumRefundable: 0
  };
  const summary = {
    taxableAmount: 0,
    taxAmtWithRates: {},
    totalPerUnitTimesQty: 0,
    taxAmount: 0,
    totalAmount: 0,
    roundOffAmt: draft.summary?.roundOffAmt || "",
    inWords: "",
    discount: 0,
    discountPct: 0,
    discountType: draft.summary.discountType
  };
  let totalBillItemPrice = 0;

  draft.billItems.forEach((billItem) => {
    if (billItem.returned) {
      const quantityTimesPerUnitPrice = chain(billItem.perUnit || 0)
        .multiply(abs(billItem.quantity || 0))
        .done();
      totalBillItemPrice = round(
        chain(totalBillItemPrice).add(quantityTimesPerUnitPrice).done(),
        2
      );
      summary.taxAmtWithRates[billItem.vatPct] = round(
        chain(billItem.vatAmtAfterDiscount)
          .add(summary.taxAmtWithRates[billItem.vatPct] || 0)
          .done(),
        2
      );
      summary.taxAmount = round(
        chain(summary.taxAmount).add(billItem.vatAmtAfterDiscount).done(),
        2
      );
    }
  });
  const inlineDiscountSum = draft.billItems.reduce(
    (acc, billItem) => (billItem.returned ? acc + billItem.discountAmt : acc),
    0
  );
  summary.discountPct =
    round(chain(inlineDiscountSum).divide(totalBillItemPrice).done() * 100, 2) || 0;
  summary.discount = inlineDiscountSum;
  summary.totalPerUnitTimesQty = totalBillItemPrice;
  summary.taxableAmount = round(chain(totalBillItemPrice).subtract(summary.discount).done(), 2);
  summary.totalAmount = -abs(
    round(
      chain(totalBillItemPrice)
        .subtract(summary.discount)
        .add(summary.taxAmount)
        .add(summary.roundOffAmt)
        .done(),
      2
    )
  );
  summary.inWords = numberToWords(abs(summary.totalAmount));
  refundInfo.maximumRefundable = chain(abs(summary.totalAmount))
    .subtract(refundInfo.previousDueAmt)
    .done();

  return { ...draft, summary, refundInfo };
}

export const distributePayment = (draft: BillDocumentType): BillDocumentType => {
  const paymentInfo = cloneDeep(draft.paymentInfo);
  const totalAmount = draft.summary.totalAmount || 0;

  if (draft.ssf?.id && draft.client?.id) {
    const { balance: blc, scheme, subProduct } = draft.ssf;
    const isMedicalCase = scheme.value === SCHEME_TYPE.MEDICAL.value;
    let ssfBalance = blc.accident.value;
    if (isMedicalCase) {
      if (subProduct.value === MEDICAL_SUB_PRODUCT.OPD.value) {
        ssfBalance = blc.medical.opd;
      } else {
        ssfBalance = blc.medical.ipd + blc.medical.opd;
      }
    }

    const coveragePercent = isMedicalCase ? 80 : 100;
    const totalAmountToCoverBySsf = totalAmount * (coveragePercent / 100);
    if (totalAmountToCoverBySsf <= ssfBalance) {
      const paidAmtByPatient = totalAmount - totalAmountToCoverBySsf;
      if (paymentInfo.paid === true) {
        paymentInfo.paidAmount = paidAmtByPatient;
        paymentInfo.paymentDistribution = {
          paymentFromSsf: totalAmountToCoverBySsf,
          paymentFromBalance: 0,
          additionalPayment: paidAmtByPatient
        };
      } else {
        paymentInfo.paidAmount = 0;
        paymentInfo.paymentDistribution = {
          paymentFromSsf: totalAmountToCoverBySsf,
          paymentFromBalance: 0,
          additionalPayment: 0
        };
      }
    } else {
      const paidAmtByPatient = totalAmount - ssfBalance;
      paymentInfo.paidAmount = paidAmtByPatient;
      paymentInfo.paymentDistribution = {
        paymentFromSsf: ssfBalance,
        paymentFromBalance: 0,
        additionalPayment: paidAmtByPatient
      };
    }
  } else {
    const { balance } = draft.client;
    if (paymentInfo.paymentDistribution.additionalPayment > 0 && balance > 0) {
      paymentInfo.paymentDistribution = {
        paymentFromBalance: 0,
        additionalPayment: totalAmount
      };
      return { ...draft, paymentInfo };
    }

    if (balance > 0) {
      if (paymentInfo.paymentMethod !== paymentOptionsEnum.balance) {
        if (paymentInfo.paid) {
          // if paid all, payment can be distributed automatically
          if (paymentInfo.paymentDistribution.additionalPayment > 0) {
            if (paymentInfo.paymentDistribution.additionalPayment > totalAmount) {
              paymentInfo.paymentDistribution.additionalPayment = totalAmount;
            }
            const deductableFromBalance = chain(totalAmount)
              .subtract(paymentInfo.paymentDistribution.additionalPayment)
              .done();
            paymentInfo.paymentDistribution = {
              ...paymentInfo.paymentDistribution,
              paymentFromBalance: deductableFromBalance < balance ? deductableFromBalance : balance
            };
            paymentInfo.paidAmount = totalAmount;
          } else {
            const paymentFromBalance = balance <= totalAmount ? balance : totalAmount;
            const remainingAmount = chain(totalAmount).subtract(paymentFromBalance).done();

            paymentInfo.paymentDistribution = {
              paymentFromBalance,
              additionalPayment: remainingAmount > 0 ? remainingAmount : 0
            };
            paymentInfo.paidAmount = chain(paymentFromBalance)
              .add(paymentInfo.paymentDistribution.additionalPayment)
              .done();
          }
        } else if (paymentInfo.paymentDistribution.additionalPayment > 0) {
          // user manually enters additinal paid amount
          const paymentFromBalance = balance <= totalAmount ? balance : totalAmount;
          paymentInfo.paymentDistribution = {
            ...paymentInfo.paymentDistribution,
            paymentFromBalance
          };
          paymentInfo.paidAmount = chain(paymentFromBalance)
            .add(paymentInfo.paymentDistribution.additionalPayment || 0)
            .done();
        } else if (paymentInfo.paymentDistribution.paymentFromBalance !== 0) {
          const paymentFromBalance = balance <= totalAmount ? balance : totalAmount;
          paymentInfo.paymentDistribution = {
            additionalPayment: 0,
            paymentFromBalance
          };
          paymentInfo.paidAmount = chain(paymentFromBalance)
            .add(paymentInfo.paymentDistribution.additionalPayment || 0)
            .done();
          paymentInfo.paid = paymentInfo.paidAmount >= totalAmount;
        }
      } else if (balance >= totalAmount) {
        paymentInfo.paid = true;
        paymentInfo.paidAmount = totalAmount;
        paymentInfo.paymentMethod = paymentOptionsEnum.balance;
        paymentInfo.paymentDistribution = {
          ...paymentInfo.paymentDistribution,
          additionalPayment: 0,
          paymentFromBalance: paymentInfo.paidAmount
        };
      } else {
        // change payment method to be cash (default) since balance
        // is not enough to cover totalAmount of the bill
        // but all the balance should get used up, at this point,
        // and additional payment should be able to be done
        paymentInfo.paymentMethod = paymentOptionsEnum.cash;
        const paymentFromBalance = balance;
        const additionalPayment = chain(totalAmount).subtract(paymentFromBalance).done();
        paymentInfo.paymentDistribution = {
          paymentFromBalance,
          additionalPayment
        };
        paymentInfo.paid = true;
        paymentInfo.paidAmount = totalAmount;
      }
    } else if (paymentInfo.paymentMethod === paymentOptionsEnum.balance && balance <= 0) {
      paymentInfo.paymentMethod = paymentOptionsEnum.cash;
      paymentInfo.paymentDistribution = {
        paymentFromBalance: 0,
        additionalPayment: paymentInfo.paidAmount
      };
    } else {
      paymentInfo.paymentDistribution = {
        paymentFromBalance: 0,
        additionalPayment: paymentInfo.paidAmount
      };
    }
  }
  return { ...draft, paymentInfo };
};

export const reformDraftData = (draft: BillDocumentType): BillDocumentType => {
  // Credit Note, remove items that are not returned
  if (draft.type === DocumentTypes.CREDITNOTE) {
    // might cause a problem, since updating draft may
    // change (not sure) total amount or refundable amount
    // so, if refunded amount is less than max. refundable,
    // there is possibility that refunded amount again gets reset to max. refundable
    // not sure, will check once multiple payment for credit note comes
    // and refunded amount is allowed to be edited
    draft.billItems = draft.billItems.filter((item) => item.returned);
  }

  // Handle Advance, cleanup while finalising bill
  if (hasOwnProperty(Object(draft.paymentInfo), "paymentDistribution")) {
    const shouldOverwritePaymentInfo =
      draft.paymentInfo.paidAmount === draft.paymentInfo.paymentDistribution.paymentFromBalance &&
      draft.paymentInfo.paymentMethod !== paymentOptionsEnum.balance;

    if (shouldOverwritePaymentInfo && !draft.paymentInfo?.paymentDistribution?.paymentFromSsf) {
      draft.paymentInfo.paymentMethod = paymentOptionsEnum.balance;
      delete draft.paymentInfo.paymentDistribution;
    }

    if (draft.paymentInfo.paidAmount === 0) {
      draft.paymentInfo.paymentMethod = paymentOptionsEnum.noPayment;
    }
  }

  return draft;
};

export const mergeBatchInfo = (
  billItems: Array<BillItemType & { batchId: string }> = []
): Array<Partial<BillItemType>> =>
  billItems.map((billItem) => {
    let source;
    if (billItem.batchId) {
      source = BillItemSource.stocks;
    } else {
      source = BillItemSource.services;
    }
    return omit(
      {
        ...billItem,
        batchInfo: {
          quantity: billItem.quantity,
          batchId: billItem.batchId || "",
          expiryDate: billItem?.expiryDate
        },
        ...(!billItem.source ? { source } : {})
      },
      "batchId"
    );
  });

export const splitBatchInfo = (billItems: Array<BillItemType> = []): Array<Partial<BillItemType>> =>
  billItems.map((item) =>
    omit(
      {
        ...item,
        ...(item.batchInfo.batchId ? { batchId: item.batchInfo.batchId } : {}),
        ...{ expiryDate: item.batchInfo?.expiryDate }
      },
      ["batchInfo"]
    )
  );

export function mergeServicesProducts(
  services: Array<ProductInterface>,
  products: Array<StockProducts>,
  isSsfBilling: boolean
): Array<Record<string, unknown>> {
  return [
    ...services
      ?.filter(({ active }) => active)
      .map((service) =>
        omit(
          {
            ...service,
            structuredInfo: service.info,
            source: BillItemSource.services
          },
          "info"
        )
      ),
    ...products
      ?.filter(({ active, productType }) => active && productType === 1)
      .map((item) => ({
        ...item,
        source: BillItemSource.stocks,
        ...(isSsfBilling ? { code: SSF_MEDICINE_CODE } : {})
      }))
  ];
}

export const hasFiscalYear2079Started = (): boolean => {
  // check if fiscal 2079 has started
  const today = moment();
  const fiscalYear2079 = moment(convertBStoAD(2079, 4, 1));

  return fiscalYear2079.isBefore(today);
};

export const showUnitAfterQuantity = (column: BillColumnsType): boolean =>
  column === BillColumns.QUANTITY;

export const getBillNumberType = (draft: BillDocumentType): string => {
  let billNumberType;

  if (draft.isInternal) {
    // check if fiscal 2079 has started
    if (!hasFiscalYear2079Started()) {
      billNumberType = "internalBilling";
    } else if (draft.type === DocumentTypes.INVOICE) {
      billNumberType = TrackingNumType.SALES;
    } else {
      billNumberType = TrackingNumType.CREDIT_NOTE;
    }
  } else {
    billNumberType = "billing";
  }

  return billNumberType;
};

export const marginForNameColumn = (column: BillColumns): number =>
  column === BillColumns.DESCRIPTION ? 1 : 0;

export const showInfoColumn = (billData: BillType): boolean =>
  // show info column if any item of bill isNarcotics or if enabled from column settings
  (billData.document.billItems || []).some((item) => item.productData?.isNarcotics) ||
  Boolean(billData.document.settings.extraColumnSettings?.info);

export const getColumnTextStyles = ({
  column,
  index,
  fontSize = scaleFont("0.33cm")
}: {
  fontSize?: string;
  index: number;
  column: BillColumns;
}): React.CSSProperties => ({
  pageBreakInside: "avoid",
  fontWeight: [BillColumns.DESCRIPTION, BillColumns.GROSS_TOTAL].includes(column) ? 500 : 400,
  fontSize,
  textAlign: index > 2 ? "center" : "left",
  whiteSpace: column === BillColumns.INFO ? "pre" : "normal"
});

export const getPaymentOptions = (
  draft: BillDocumentType,
  isCreditNote: boolean
): {
  enablePaymentSelection: boolean;
  paymentMethodList: string[];
  selectedPaymentMethod: string;
} => {
  let enablePaymentSelection = true;
  let paymentMethodList = paymentOptions;
  let selectedPaymentMethod = draft.paymentInfo?.paymentMethod ?? "";

  if (draft && isCreditNote) {
    const { previousPaidAmt, previousPaymentMethod, maximumRefundable } = draft.refundInfo;

    paymentMethodList = paymentOptions.filter((option) => option !== paymentOptionsEnum.balance);

    if (
      (previousPaymentMethod === paymentOptionsEnum.noPayment && previousPaidAmt === 0) ||
      maximumRefundable <= 0
    ) {
      enablePaymentSelection = false;
    }

    if (maximumRefundable > 0) {
      enablePaymentSelection = true;
      selectedPaymentMethod =
        selectedPaymentMethod === paymentOptionsEnum.noPayment
          ? paymentOptionsEnum.cash
          : selectedPaymentMethod;
      // eslint-disable-next-line max-len
      paymentMethodList = paymentMethodList.filter(
        (option) => option !== paymentOptionsEnum.noPayment
      );
    }
  } else {
    paymentMethodList = paymentOptions.filter((option) => {
      if (option === paymentOptionsEnum.noPayment) return false;
      // eslint-disable-next-line max-len
      if (option === paymentOptionsEnum.balance && draft.client.balance < draft.summary.totalAmount)
        return false;
      return true;
    });
  }

  return { enablePaymentSelection, paymentMethodList, selectedPaymentMethod };
};
