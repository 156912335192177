import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./store/hooks";
import NavigationBar from "./containers/NavigationBar";
import Notifications from "./containers/FlashMessage/Notification";
import { getLanguage, setLanguage } from "../translations/translate";
import Modals from "./containers/Modals/Modals";
import { getAccount } from "./actions/user";
import Routes from "./Routes";
import "./styles/index.scss";
import ErrorBoundary from "./components/ErrorBoundary";
import StyleWrapper from "./StyleWrapper";
import OfflineMessage from "./components/OfflineMessage";
import { getServiceTags } from "./actions/serviceTags";
import MaintenanceModal from "./containers/Modals/MaintenanceModal/MaintenanceModal";
import HubspotChatBot from "./containers/hubspot/HubspotChatBot";
import ReleaseNoteModal from "./containers/Modals/ReleaseNoteModal/ReleaseNoteModal";
import ResourceCentreNotificationModal from "./containers/Modals/ResourceCentre/ResourceCentreNotificationModal";
import { RootState } from "./store";
import BottomBar from "./containers/NavigationBar/BottomNavBar";
import useLogoutListener from "./hooks/useLogoutListener";

type I18nContextProps = {
  language: string;
  changeLanguage: (lang) => void;
};

export const I18nContext = React.createContext<Partial<I18nContextProps>>({
  language: "np",
  changeLanguage: () => undefined
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App: React.ReactNode = () => {
  const authenticated = useSelector((state: RootState) => state.authenticated);
  const [showSupportChat, setShowSupportChat] = React.useState(false);
  const [unreadSupportMessageCount, setUnreadSupportMessageCount] = React.useState(0);
  const [state, setState] = React.useState({ language: getLanguage() });
  const dispatch = useAppDispatch();

  useLogoutListener();

  React.useEffect(() => {
    if (!showSupportChat) {
      setUnreadSupportMessageCount(0);
    }
  }, [showSupportChat]);

  React.useEffect(() => {
    if (authenticated) {
      dispatch(getAccount());
      dispatch(getServiceTags());
    }
  }, []);

  const changeLanguage = (language) => {
    setState({ language });
    setLanguage(language);
  };

  const { language } = state;

  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <StyleWrapper>
        <ErrorBoundary>
          <OfflineMessage />
          <I18nContext.Provider value={{ language, changeLanguage }}>
            <div style={{ display: "flex" }}>
              <MaintenanceModal />
              {authenticated && <ReleaseNoteModal />}
              {authenticated && <ResourceCentreNotificationModal />}
              <Modals />
              <Notifications />
              {authenticated && (
                <HubspotChatBot
                  show={showSupportChat}
                  onClose={() => {
                    setShowSupportChat(false);
                  }}
                  onMessage={(message) => {
                    setUnreadSupportMessageCount(message.unreadCount);
                  }}
                />
              )}
              {authenticated && (
                <NavigationBar
                  setShowSupportChat={() => setShowSupportChat(!showSupportChat)}
                  unreadSupportMessageCount={unreadSupportMessageCount}
                />
              )}
              <div
                style={{
                  paddingTop: 40,
                  width: "100%",
                  height: "100vh",
                  overflowX: "hidden"
                }}
              >
                <Routes />
              </div>
              {/* isRnWebView comes from injected javasriptt from rn app */}
              {window.isRnWebView && authenticated && <BottomBar />}
            </div>
          </I18nContext.Provider>
        </ErrorBoundary>
      </StyleWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
