import React, { Component } from "react";
import decorators from "react-treebeard/dist/components/Decorators";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  MenuItem,
  ListItem,
  ListItemText,
  Box,
  CircularProgress
} from "@mui/material";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { TreeView, TreeItem } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import { blue, pink } from "@mui/material/colors";
import { setTimeout } from "timers";
import isEmpty from "lodash/isEmpty";
import deburr from "lodash/deburr";
import {
  getBatchDiagnosis,
  getChildren,
  getSibblings,
  searchDisease
} from "../../../api/assessment";
import { tl, t } from "../../../components/translate";
import Search from "../SearchInputs";
import styles from "../Assessment.module.css";
import "../index.css";
import CutomContainer from "./CustomContainer";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      light: "#ff79b0",
      main: pink.A200,
      dark: "#c60055",
      contrastText: "#fff"
    }
  }
});

const CustomHeader = ({ node, style }) => (
  <div style={style.base}>
    <div style={{ ...style.title, display: "flex" }}>{`${node.title}`}</div>
  </div>
);
const CutomToggle = () => <ArrowDropDownIcon />;

const buildItemsHash = (items = [], parentMapHash = {}) => {
  const parentMap = { ...parentMapHash };
  items.forEach((item) => {
    parentMap[item.parentId] = parentMap[item.parentId] || {};
    parentMap[item.parentId][item.id] = item;
  });
  return parentMap;
};

const Leaf = ({ node, checkedLeaf, onCheck, onSelect, selectedTree }) => (
  <Box
    component="div"
    onClick={() => {
      onCheck(node.id);
      onSelect();
    }}
    display="flex"
  >
    {checkedLeaf === node.id && <CheckIcon />}
    <Typography component="span">
      <Box component="span">
        {selectedTree == node.id ? (
          <>
            <b>{node.code}</b>:{node.title}
          </>
        ) : (
          <>
            <b>{node.code} </b>:{node.title}
          </>
        )}
      </Box>
    </Typography>
  </Box>
);

const ChildLeaf = ({ node, selectedTree }) => (
  <Box component="div" display="flex">
    <Typography component="span">
      <Box component="span">
        {selectedTree == node.id ? (
          <>
            <b>{node.code}</b>:{node.title}
          </>
        ) : (
          <>
            <b>{node.code}</b>:{node.title}
          </>
        )}
      </Box>
    </Typography>
  </Box>
);

class Diagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      diseaseList: [],
      diagnosisName: "",
      number: [],
      alphabet: [],
      searchString: undefined,
      buttonData: [],
      modalSearch: "",
      firstSearch: false,
      disabled: false,
      icdDisease: [],
      itemsHash: {},
      checkedLeaf: null,
      suggestions: [],
      query: "",
      timeoutRef: { current: null },
      search: "",
      defaultExpand: [],
      showTree: true,
      clientPhoneNo: "",
      selectedTree: undefined
    };
  }

  componentDidMount() {
    getBatchDiagnosis().then((res) => {
      this.setState({ icdDisease: res });
      this.setState({ itemsHash: buildItemsHash(res, this.state.itemsHash) });
    });
  }

  renderNodes(parentId = null) {
    return (
      <>
        {this.state.itemsHash[parentId] &&
          Object.values(this.state.itemsHash[parentId]).map((node) =>
            !node.isLeaf ? (
              <TreeItem
                key={node.id}
                nodeId={node.id}
                label={
                  <ChildLeaf
                    name="okhati-icd-leaves"
                    selectedTree={this.state.selectedTree}
                    node={node}
                  />
                }
              >
                {this.state.itemsHash[node.id] ? (
                  this.renderNodes(node.id)
                ) : (
                  <CircularProgress size={16} />
                )}
              </TreeItem>
            ) : (
              <TreeItem
                nodeId={node.id}
                label={
                  <Leaf
                    selectedTree={this.state.selectedTree}
                    name="okhati-icd-leaves"
                    node={node}
                    checkedLeaf={this.state.checkedLeaf}
                    onSelect={() => {
                      setTimeout(() => {
                        this.setState({ open: false });
                      }, 1000);
                    }}
                    onCheck={(id) =>
                      this.setState(
                        {
                          checkedLeaf: id,
                          searchString: node.title,
                          diagnosisName: `${node.code} ${node.title}`
                        },
                        () => {
                          this.props.onChange({
                            diagnosis: `${node.code} ${node.title}`,
                            icdCode: node.code
                          });
                        }
                      )
                    }
                  />
                }
              />
            )
          )}
      </>
    );
  }

  async search(q) {
    return new Promise((resolve) => {
      searchDisease(q).then((result) => {
        resolve(
          result.filter(
            ({ title, code }) =>
              ~title
                .toLowerCase()
                .indexOf(q.toLowerCase() || ~code.toLowerCase().indexOf(q.toLowerCase()))
          )
        );
      });
    });
  }

  getAncestorsIds = (node, nodes) => {
    const map = nodes.reduce((m, n) => {
      m[n.id] = n;
      return m;
    }, {});
    const parents = [];
    const addParent = (parentId) => {
      parents.push(parentId);
      const parent = map[parentId];
      if (parent) {
        addParent(parent.parentId);
      }
    };
    addParent(node.parentId);
    return parents;
  };

  openTreeFn(node) {
    this.setState({ selectedTree: node.id });
    if (!this.state.itemsHash[node.id]) {
      getSibblings(node.id).then((response) => {
        const allParentIds = this.getAncestorsIds(node, response);
        this.setState(
          {
            defaultExpand: allParentIds,
            itemsHash: buildItemsHash(response, this.state.itemsHash),
            showTree: false
          },
          () => this.setState({ showTree: true })
        );
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.diagnosis && !isEmpty(props.diagnosis) && props.diagnosis.diagnosis) {
      const DiagnosisName =
        (props.diagnosis.code ? props.diagnosis.code : "") + props.diagnosis.diagnosis;
      this.setState({ diagnosisName: DiagnosisName });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  treeView() {
    return (
      <>
        {this.state.showTree ? (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={this.state.defaultExpand}
            onNodeToggle={(e, nodeId) => {
              if (!this.state.itemsHash[nodeId[0]]) {
                getChildren(nodeId[0]).then((res) => {
                  this.setState({ itemsHash: buildItemsHash(res, this.state.itemsHash) });
                });
              }
            }}
          >
            {this.renderNodes()}
          </TreeView>
        ) : (
          ""
        )}
      </>
    );
  }

  renderInput(inputProps) {
    const {
      InputProps,
      clearValue,
      onChange,

      ref,
      ...other
    } = inputProps;

    return (
      <TextField
        disabled={other.edit}
        InputProps={{
          inputRef: ref,

          ...InputProps
        }}
        {...other}
      />
    );
  }

  renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;

    return (
      <MenuItem {...itemProps} key={suggestion.title} selected={isHighlighted} component="div">
        <Typography variant="body2">{suggestion.title}</Typography>
      </MenuItem>
    );
  }

  getSuggestions(value, { showEmpty = false } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0 && !showEmpty
      ? []
      : this.state.suggestions.filter((suggestion) => {
          const keep = suggestion.title.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }
          return keep;
        });
  }

  getDiseasesSuggestion(event) {
    if (event.length > 2) {
      this.state.timeoutRef.current = setTimeout(
        () => {
          searchDisease(event).then((results) => {
            this.setState({ suggestions: results });
          });
        },
        event.length === 3 ? 0 : 500
      );
    } else if (event.length === 0) {
      this.setState({ suggestions: [] });
    }

    return () => {
      clearTimeout(this.state.timeoutRef.current);
      this.state.timeoutRef.current = null;
    };
  }

  getChildrenFromParentNode(id) {
    getChildren(id).then((res) => {
      // console.log(res);
    });
  }

  render(props) {
    decorators.Header = CustomHeader;
    decorators.Container = CutomContainer;
    decorators.Toggle = CutomToggle;

    const { label, hidetitle } = this.props;
    return (
      <>
        <Box className={styles.assessmentRow}>
          {!hidetitle && (
            <Box className={styles.assessmentLabel}>
              <Typography>
                <Box component="span" fontWeight="600">
                  {label}
                </Box>
              </Typography>
            </Box>
          )}
          <Box className={styles.assessmentField} display="flex">
            <TextField
              fullWidth
              label={hidetitle ? label : ""}
              InputLabelProps={{
                shrink: true
              }}
              data-testmation="diagnosisTextField"
              onChange={(e) => {
                this.setState({
                  diagnosisName: e.target.value
                });
                this.props.onChange({ diagnosis: e.target.value });
              }}
              value={this.state.diagnosisName}
              margin="dense"
              variant="outlined"
              placeholder={t("assessment.typeDiagnosis")}
              InputProps={{
                endAdornment: (
                  <>
                    <Button
                      data-testmation="diagnosisButton"
                      variant="contained"
                      style={{ padding: 0, minWidth: "30px", cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          open: !this.state.open
                        });
                      }}
                    >
                      <Typography>
                        <Box component="span" fontWeight="600">
                          {tl("assessment.ICD")}
                        </Box>
                      </Typography>
                    </Button>
                  </>
                )
              }}
            />
          </Box>
        </Box>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <Typography>
                <Box component="span" fontWeight="600">
                  {tl("assessment.diseaseSearch")}
                </Box>
              </Typography>
              <CloseIcon style={{ cursor: "pointer" }} onClick={this.handleClose} />
            </Box>
          </DialogTitle>

          <DialogContent>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Search
                  data-testmation="diagnosisSearchTextField"
                  searchFn={this.search}
                  onChange={(e) => {
                    this.setState({ clientPhoneNo: e });
                  }}
                  Namevalue={this.state.clientPhoneNo}
                  placeholder={t("assessment.searchICD")}
                  onItemSelect={(e) => {
                    this.setState({
                      showBox: true,
                      clientPhoneNo: `${e.code} ${e.title}`,
                      open: false
                    });
                    this.props.onChange({ code: e.code, diagnosis: e.title });
                    this.openTreeFn(e);
                  }}
                  itemRenderer={({ title, code }) => (
                    <>
                      <ListItem button>
                        <ListItemText>
                          <div>
                            {code}:{title}
                          </div>
                        </ListItemText>
                      </ListItem>
                    </>
                  )}
                />
              </Grid>
            </Grid>

            <Box>
              <Typography>
                <Box component="span" fontWeight="600" pt={5} pb={3}>
                  {tl("assessment.diseaseClassificationTree")}
                </Box>
              </Typography>

              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Box>{this.treeView()}</Box>
                </ThemeProvider>
              </StyledEngineProvider>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default Diagnosis;
