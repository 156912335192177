import React from "react";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import produce from "immer";
import { startCase } from "lodash";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import * as ipdClientActions from "../../../actions/ipd";
import IpdInputField from "../IPDInputField/IpdInputField";
import Diagnosis from "../../Assessment/Diagnosis/Diagnosis";
import VitalsPopUp from "../../Assessment/Vitals/VitalsPopUp";
import { ProgressRecord, IProgressRecordVital, IMedicine } from "../../../interfaces/IpdInterface";
import { tl } from "../../../components/translate";

const initilIpdProgressRecord: ProgressRecord = {
  diagnosis: { diagnosis: "", icdCode: "" },
  diet: "",
  allergy: "",
  examination: "",
  treatmentProvided: "",
  operationSummary: "",
  medicationSummary: [],
  remarks: "",
  vitals: [],
  id: null
};

const IpdProgressRecord = ({
  onSave,
  onCancel,
  showFooter = true,
  ipdRecord,
  navigateTo,
  afterSave
}) => {
  const [progressRecord, setProgressRecord] =
    React.useState<ProgressRecord>(initilIpdProgressRecord);
  const [isVitalPopup, setIsVitalPopup] = React.useState(false);

  const [saving, setSaving] = React.useState(false);

  function setVitals(vital) {
    const updatedState = produce(progressRecord, (draft) => {
      draft.vitals.push(...vital);
    });

    setProgressRecord(updatedState);
  }

  return (
    <Panel
      wrapperStyle={{ minWidth: "770px", maxWidth: "770px" }}
      onClose={onCancel}
      title={tl("progressRecord.createIPDProgressRecord")}
      footer={
        showFooter && (
          <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
            <Box />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button
                data-testmation="progress.cancel"
                onClick={onCancel}
                style={{ marginRight: "32px" }}
              >
                <Typography>cancel</Typography>
              </Button>
              <StatefulButton
                data-testmation="ipdSave"
                variant="contained"
                color="primary"
                disabled={saving}
                onClick={async () => {
                  if (progressRecord) {
                    setSaving(true);

                    await onSave({ ...progressRecord, id: ipdRecord.id }).then(() => {
                      setSaving(false);
                      onCancel();
                      if (afterSave) {
                        afterSave();
                      } else {
                        navigateTo(`/ipd/${ipdRecord.id}?filter=admitted`);
                      }
                    });
                  }
                }}
                isLoading={saving}
                circularProgressProps={{ size: 16 }}
              >
                <Typography>save</Typography>
              </StatefulButton>
            </Box>
          </Box>
        )
      }
    >
      <Box
        sx={{
          height: "calc(100vh - 100px)",
          overflowY: "auto",
          px: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <Diagnosis
          label="Diagnosis"
          hidetitle
          diagnosis={progressRecord.diagnosis}
          onChange={(v) => {
            const newV = {
              diagnosis: v.diagnosis,
              icdCode: v.code ? v.code : ""
            };
            setProgressRecord({ ...progressRecord, diagnosis: newV });
          }}
        />

        <IpdInputField
          testmationLabel="examination"
          label="Examination"
          placeholder="Examination"
          onChange={(e) =>
            setProgressRecord({
              ...progressRecord,
              examination: e.target.value
            })
          }
        />
        <IpdInputField
          testmationLabel="treatmentProvided"
          label="Treatment Provided"
          placeholder="Treatment Provided"
          onChange={(e) =>
            setProgressRecord({
              ...progressRecord,
              treatmentProvided: e.target.value
            })
          }
        />

        <IpdInputField
          testmationLabel="OperationSummary"
          label="Operation Summary"
          placeholder="Operation Summary"
          onChange={(e) =>
            setProgressRecord({
              ...progressRecord,
              operationSummary: e.target.value
            })
          }
        />

        <IpdInputField
          testmationLabel="allergy"
          label="Allergy"
          placeholder="Patient's allergies"
          onChange={(e) => setProgressRecord({ ...progressRecord, allergy: e.target.value })}
        />

        <IpdInputField
          testmationLabel="diet"
          label="Diet"
          placeholder="Patient's Diet"
          onChange={(e) =>
            setProgressRecord({
              ...progressRecord,
              diet: e.target.value
            })
          }
        />

        {Boolean(ipdRecord.prescribedMedications?.length) && (
          <Box display="flex" flexDirection="column">
            <Typography fontWeight={600}>
              <span>Select Medicine</span>
            </Typography>
            <Box display="flex" flexDirection="column">
              {ipdRecord.prescribedMedications.map((item: IMedicine) => {
                const { brand, genericName } = item;

                return (
                  <Box key={item.id} display="flex" alignItems="center">
                    <FormControlLabel
                      label={
                        <Typography>
                          {brand}
                          {genericName}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              const updatedState = produce(progressRecord, (draft) => {
                                draft.medicationSummary.push(item);
                              });
                              setProgressRecord(updatedState);
                            } else {
                              const updatedState = produce(progressRecord, (draft) => {
                                draft.medicationSummary = draft.medicationSummary.filter(
                                  (m) => m.id !== item.id
                                );
                              });
                              setProgressRecord(updatedState);
                            }
                          }}
                        />
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        <div>
          <Button
            data-testmation="ipdVitals"
            onClick={() => setIsVitalPopup(true)}
            color="primary"
            variant="outlined"
            fullWidth
          >
            Add Vitals
          </Button>
          {isVitalPopup && (
            <VitalsPopUp
              isOpen={isVitalPopup}
              onClose={() => {
                setIsVitalPopup(false);
              }}
              onSave={(vitals) => {
                setIsVitalPopup(false);
                setVitals(vitals);
              }}
            />
          )}
          {Boolean(progressRecord.vitals?.length) && (
            <div>
              {progressRecord.vitals?.map((vital: IProgressRecordVital, index: number) => (
                <Typography key={vital.name}>
                  {`${index + 1}. ${startCase(vital.name)} ${vital.reading} ${vital.unit} ${
                    vital.extraInfo ? `(${vital.extraInfo})` : ""
                  }`}
                </Typography>
              ))}
            </div>
          )}
        </div>
        <IpdInputField
          testmationLabel="remarks"
          label="Remarks"
          placeholder="Remarks"
          onChange={(e) => setProgressRecord({ ...progressRecord, remarks: e.target.value })}
        />
      </Box>
    </Panel>
  );
};

export default connect(
  () => {
    // Do nothing
  },
  (dispatch) => ({
    onSave: async (progressRecord) => {
      await dispatch(ipdClientActions.createIpdProgressRecord(progressRecord));
    },
    navigateTo: (url) => dispatch(push(url))
  })
)(IpdProgressRecord);
