import { Box, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import { chain } from "mathjs";
import moment from "moment";
import React from "react";
import { scaleFont } from "../../../components/Print/Print";
import { tl } from "../../../components/translate";
import classNames from "../../../helpers/classNames";
import hasOwnProperty from "../../../helpers/object";
import { BillType, DiscountBasedOn, DocumentTypes } from "../../../interfaces/BillInterfaces";
import { spFromIdSelector, spFullNameSelector } from "../../../reducers/serviceProvider";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import "./PrintBill.scss";
import {
  BillColumns,
  getBillColumns,
  getColumnTextStyles,
  marginForNameColumn,
  showInfoColumn,
  showUnitAfterQuantity
} from "../../../actions/helpers/billHelper";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import BillPrintSummary from "../BillPrintSummary";
import BillPrintAmounts from "./BillPrintAmounts";

interface Props {
  billData: BillType;
  SPs: ServiceProvider[];
  relatedSettings: { [key: string]: boolean };
  clientBalance: number;
  hidePaymentMethodOnPrint?: boolean;
  isVatRegistered: boolean;
}

const DescribeBillV2 = ({
  billData,
  SPs,
  relatedSettings,
  isVatRegistered,
  clientBalance,
  hidePaymentMethodOnPrint = false
}: Props): JSX.Element | null => {
  if (!billData || !billData.document) return null;
  const showInfo = showInfoColumn(billData);

  const billColumns = getBillColumns(showInfo).filter((header) => {
    if (header === BillColumns.DISCOUNT_AMT) {
      return !(
        [BillColumns.DISCOUNT_AMT].includes(header) &&
        billData.document?.settings.discountSettings.discountBasis === DiscountBasedOn.invoice
      );
    }
    if (header === BillColumns.VAT_PCT) {
      if (isVatRegistered) {
        return header;
      }
      return false;
    }
    return header;
  });

  const showIndividualVAT =
    Object.keys(billData.document.summary.taxAmtWithRates || {}).filter(
      (vatPct) => Number(vatPct) !== 0
    ).length > 1;
  let netTotal;
  if (billData.document.type === DocumentTypes.CREDITNOTE) {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .add(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  } else {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .subtract(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  }

  return (
    <Box>
      <Box>
        <Box
          component="div"
          display="flex"
          borderTop="1px solid black"
          borderRight={relatedSettings.isBordered && "1px solid black"}
          borderBottom={relatedSettings.isBordered && "1px solid black"}
          borderLeft={relatedSettings.isBordered && "1px solid black"}
          paddingBottom="0.2cm"
          p="0.3cm"
          className={!relatedSettings.isBordered && "borderBotBlack1"}
          style={
            relatedSettings.isBordered
              ? getSectionStyle(relatedSettings.headerColor).rawHeaderStyle
              : {}
          }
        >
          {billColumns.map((column, i) => (
            <Box
              mx={marginForNameColumn(column)}
              component="div"
              key={column}
              className={classNames({
                grow1: i === 0 || i === 2,
                flex3: i === 1 || (i === 2 && showInfo),
                flex1: i > 2
              })}
            >
              <Typography component="div">
                <Box
                  textAlign={i > 2 ? "center" : "left"}
                  style={{ textTransform: "uppercase" }}
                  fontWeight={600}
                  fontSize={scaleFont("0.27cm")}
                  whiteSpace="pre"
                >
                  {tl(`billing.billItem.${column}`)}
                </Box>
              </Typography>
            </Box>
          ))}
        </Box>

        <Box component="div" paddingBottom="0.45cm">
          {billData.document.billItems.map((item, index) => (
            <Box
              component="div"
              key={item.sNo}
              display="flex"
              style={
                relatedSettings.isBordered
                  ? {
                      pageBreakInside: "avoid"
                    }
                  : {}
              }
              borderTop={relatedSettings.isBordered && index !== 0 && "0.5px solid lightgrey"}
              borderBottom={
                index === billData.document.billItems.length - 1
                  ? relatedSettings.isBordered && "1px solid black"
                  : relatedSettings.isBordered && "0.5px solid lightgrey"
              }
              className={
                index === billData.document.billItems.length - 1 &&
                !relatedSettings.isBordered &&
                "borderBotBlack1"
              }
            >
              {billColumns.map((column, i) => (
                <Box
                  borderLeft={i === 0 && relatedSettings.isBordered && "1px solid black"}
                  borderRight={
                    i === billColumns.length - 1 && relatedSettings.isBordered && "1px solid black"
                  }
                  paddingLeft={i === 0 && "0.3cm"}
                  paddingRight={i === billColumns.length - 1 && "0.3cm"}
                  component="div"
                  key={column}
                  className={classNames({
                    grow1: i === 0 || i === 2,
                    flex3: i === 1 || (i === 2 && showInfo),
                    flex1: i > 2
                  })}
                  paddingTop="0.2cm"
                  paddingBottom="0.2cm"
                  mx={marginForNameColumn(column)}
                >
                  <Typography component="div">
                    <Box sx={getColumnTextStyles({ index: i, column })}>
                      {["perUnit", "grossTotal"].includes(column) && item[column] !== null
                        ? Number(item[column]).toFixed(2)
                        : item[column]}{" "}
                      {showUnitAfterQuantity(column) && item.unit}
                      {column === "description" &&
                        item.serviceProviderId &&
                        `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                    </Box>
                    {column === "description" && (
                      <Box
                        sx={{ whiteSpace: "pre-wrap", display: "inline" }}
                        fontSize={scaleFont("0.23cm")}
                      >
                        {item.batchInfo?.batchId && `Batch ${item.batchInfo?.batchId}`}
                        {item.batchInfo?.expiryDate &&
                          `, Expiring ${moment(item.batchInfo.expiryDate).format("YYYY/MM/DD")}`}
                      </Box>
                    )}
                    {column === "description" &&
                      item?.subItems?.map((subitem) => (
                        <Box key={subitem.productId} paddingTop="0.05cm" className="blockContent">
                          {`◦ ${subitem.description}`}
                          {hasOwnProperty(subitem, "serviceProviderId") &&
                            subitem.serviceProviderId && (
                              <span style={{ marginLeft: "8px" }}>
                                {`(${spFullNameSelector(
                                  spFromIdSelector(SPs, subitem.serviceProviderId)
                                )})`}
                              </span>
                            )}
                        </Box>
                      ))}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", pageBreakInside: "avoid" }}>
        <Box width="50%">
          {Boolean(billData.document.remarks) && (
            <Box marginBottom="0.3cm">
              <Typography variant="subtitle2" align="left" component="div" display="block">
                <Box fontSize={scaleFont("0.3cm")} style={{ textTransform: "uppercase" }}>
                  {tl("billing.remarks")}
                </Box>
              </Typography>
              <Typography align="left" component="div">
                <Box
                  fontWeight={400}
                  fontSize={scaleFont("0.33cm")}
                  marginRight="2rem"
                  marginTop="0.1cm"
                >
                  {billData.document.remarks}
                </Box>
              </Typography>
            </Box>
          )}
          {!hidePaymentMethodOnPrint && Boolean(billData?.paymentInfo?.paymentMethod) && (
            <Box display="flex" gap={2} alignItems="center">
              <Box fontSize={scaleFont("0.3cm")} style={{ textTransform: "uppercase" }}>
                {tl("billing.paymentMethod")}
              </Box>
              <Box fontWeight={400} fontSize={scaleFont("0.33cm")} marginRight="2rem">
                {startCase(billData.paymentInfo?.paymentMethod)}
              </Box>
            </Box>
          )}
        </Box>
        <Box width="50%">
          <Box paddingBottom="0.2cm" width="100%" textAlign="right">
            <BillPrintSummary
              totalPerUnitTimesQty={billData.document.summary.totalPerUnitTimesQty}
              discount={billData.document.summary.discount}
              taxAmount={billData.document.summary.taxAmount}
              netTotal={netTotal}
              showIndividualVAT={showIndividualVAT}
              taxAmtWithRates={billData.document.summary.taxAmtWithRates}
            />
          </Box>
        </Box>
      </Box>
      <div>
        <Box display="flex">
          <Box flex={1}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: scaleFont("0.3cm"),
                pr: 3
              }}
            >
              <Typography component="span" sx={{ fontWeight: 500, mr: 0.5 }}>
                {tl("billing.inWords")} :
              </Typography>
              {billData.document.summary.inWords} only
            </Typography>
          </Box>
          <Box flex={1}>
            <BillPrintAmounts billData={billData} clientBalance={clientBalance} />
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default DescribeBillV2;
