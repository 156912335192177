import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { tl } from "../../../components/translate";
import { SmsTemplateBox } from "../MessageTemplate/SmsTemplateBox";
import PhoneEmailMultiSelect from "./PhoneEmailMultiSelect";
import { clientFullNameSelector, clientsSortedSelector } from "../../../reducers/client";
import * as clientActions from "../../../actions/client";
import {
  proseMirrorOptions,
  RichTextfield,
  deserializeNode,
  htmlToProseMirror
} from "../../../components/RichTextfield";
import useProseMirror from "../../../components/RichTextfield/useProsemirror";
import { sendMessage } from "../../../api/campaigns";
import * as NotificationActions from "../../../actions/notification";
import { IThunkDispatch, RootState } from "../../../store";
import { Client } from "../../../interfaces/ClientInterface";
import { ReverseMap } from "../../../helpers/types";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { Employee } from "../../../interfaces/User";
import hasOwnProperty from "../../../helpers/object";

export enum MESSAGE_TYPE {
  SMS = "sms",
  EMAIL = "email"
}

export type MessageType = ReverseMap<typeof MESSAGE_TYPE>;

interface MessageCreateProps {
  onCancel: () => void;
  clients: any;
  actions: any;
  initialHTMLMessage?: string;
  panelTitle?: string;
  client?: Client | ServiceProvider | Employee;
  messageType?: MessageType;
  defaultValue?: {
    name: string;
    val: string;
  };
  defaultSubject?: string;
  defaultSmsBody?: string;
}

const mediaTypeList = [
  { label: "SMS", value: "sms" },
  { label: "Email", value: "email" }
];

const MessageCreate: React.FC<MessageCreateProps> = ({
  onCancel,
  clients,
  actions,
  client,
  messageType,
  initialHTMLMessage = "",
  panelTitle = "Send Message",
  defaultValue,
  defaultSubject,
  defaultSmsBody
}) => {
  const [formData, setFormData] = React.useState({
    to: [],
    subject: defaultSubject || "",
    body: defaultSmsBody || "",
    activated: true,
    messageType
  });
  const [values, setValues] = React.useState<{ name: string; val: string }[]>(
    defaultValue ? [defaultValue] : []
  );

  React.useEffect(() => {
    if (!clients || !clients.length) actions.loadClients();
    if (hasOwnProperty(client || {}, "id")) {
      setValues([
        {
          name: clientFullNameSelector(client),
          val: messageType === MESSAGE_TYPE.SMS ? client.phone : client.email || "No email"
        }
      ]);
    }
  }, []);
  const [state, setState] = useProseMirror({
    ...proseMirrorOptions,
    doc: htmlToProseMirror(initialHTMLMessage)
  });
  const [saving, setSaving] = React.useState(false);

  const optionsForSelectPhone = (clientList = []) =>
    clientList.map((c) => ({
      name: clientFullNameSelector(c),
      val: c.phone,
      id: c.id
    }));

  const optionsForSelectEmail = (clientList = []) =>
    clientList
      .filter((c) => c.email)
      .map((c) => ({
        name: clientFullNameSelector(c),
        val: c.email,
        id: c.id
      }));

  return (
    <Panel
      onClose={onCancel}
      title={panelTitle}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              data-testmation="campaign.cancel"
              onClick={onCancel}
              style={{ marginRight: "32px" }}
            >
              <Typography>{tl("client.cancel")}</Typography>
            </Button>
            <StatefulButton
              data-testmation="campaignSend"
              variant="contained"
              color="primary"
              disabled={saving || !values?.length}
              onClick={async () => {
                setSaving(true);
                formData.to = values.map((value) => value.val);
                if (formData.messageType === "email") {
                  formData.body = deserializeNode(state.doc);
                }
                formData.to = [...new Set(formData.to)];
                await actions.send(formData);
                setSaving(false);
                onCancel();
              }}
              isLoading={saving}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{tl("campaign.msg.send")}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      }
    >
      <Box pl={5} pr={5} height=" calc(100vh - 100px)">
        <TextField
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder="Type"
          select
          margin="dense"
          label={tl("campaign.msg.type")}
          style={{ flexBasis: "200px", marginRight: "10px" }}
          value={formData.messageType || ""}
          onChange={(e) => {
            const data = cloneDeep(formData);
            data.messageType = e.target.value;
            setFormData(data);
            if (client) {
              setValues([
                {
                  name: clientFullNameSelector(client),
                  val:
                    e.target.value === MESSAGE_TYPE.SMS ? client.phone : client.email || "No email"
                }
              ]);
              return;
            }
            setValues([]);
          }}
        >
          {mediaTypeList.map((media) => (
            <MenuItem key={media.value} value={media.value} id={`unqxsmi-${media.value}`}>
              {media.label}
            </MenuItem>
          ))}
        </TextField>

        <PhoneEmailMultiSelect
          type={formData.messageType}
          value={values}
          setValue={setValues}
          options={
            formData.messageType === MESSAGE_TYPE.SMS
              ? optionsForSelectPhone(clients)
              : optionsForSelectEmail(clients)
          }
        />

        {formData.messageType === MESSAGE_TYPE.SMS && (
          <SmsTemplateBox
            showCustomKeywords={false}
            content={formData.body}
            onChange={(content) => {
              const newFormData = cloneDeep(formData);
              newFormData.body = content;
              setFormData(newFormData);
            }}
          />
        )}

        {formData.messageType === MESSAGE_TYPE.EMAIL && (
          <>
            <TextField
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="Subject"
              margin="dense"
              fullWidth
              label="Subject"
              value={formData.subject}
              onChange={(e) => {
                const data = cloneDeep(formData);
                data.subject = e.target.value;
                setFormData(data);
              }}
            />
            <RichTextfield state={state} setState={setState} />
          </>
        )}
      </Box>
    </Panel>
  );
};

MessageCreate.defaultProps = {
  client: {} as Client,
  messageType: MESSAGE_TYPE.SMS
};

export default connect(
  (state: RootState) => ({
    clients: clientsSortedSelector(state)
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      loadClients: () => dispatch(clientActions.getClients()),
      send: async (formData) => {
        await sendMessage(formData);
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: "Message sent",
            autoTimeout: true
          })
        );
      }
    }
  })
)(MessageCreate);
