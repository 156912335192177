import {
  Box,
  ClickAwayListener,
  InputAdornment,
  Paper,
  TextField,
  CircularProgress,
  Button,
  Tab,
  Typography
} from "@mui/material";
import React from "react";
import { startCase } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Panel from "../../components/Panel";
import SelectedArticle from "./SelectedArticle";
import { useKnowledgeBaseBySearchText } from "../../api/knowledgeBase";
import SearchResultList from "./SearchResultList";
import { KnowledgeBaseDocument } from "../../interfaces/KnowledgeBase";
import RelatedArticleList from "./RelatedArticleList";
import useDebounceValue from "../../hooks/useDebouncedValue";
import ModuleWiseArticles from "./ModuleWiseArticles";
import PreviousReleaseNotes from "./PreviousReleaseNotes";
import ContactInfo from "./ContactInfo";
import VideoGrid from "./VideoGrid";
import { featuresVideos, supportVideos } from "../../constants";

interface KnowledgeBaseProps {
  handleViewClose: () => void;
}

enum Tabs {
  FREQUENTLY_ASKED_QUESTIONS = "frequentlyAskedQuestions",
  MODULES = "modules",
  RELEASE_NOTES = "releaseNotes",
  INSTRUCTION_VIDEOS = "instructionVideos",
  CONTACT = "contact"
}
enum VideoTabs {
  FEATURE = "FEATURE",
  SUPPORT = "SUPPORT"
}
const tabs = Object.values(Tabs);

const KnowledgeBase = ({ handleViewClose }: KnowledgeBaseProps): JSX.Element => {
  const [searchText, setSearchText] = React.useState("");
  const [showSearchList, setShowSearchList] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState<null | KnowledgeBaseDocument>(null);
  const debouncedSearchTerm = useDebounceValue(searchText);
  const { isLoading, data } = useKnowledgeBaseBySearchText(debouncedSearchTerm);
  const [selectedTab, setSelectedTab] = React.useState<Tabs>(Tabs.FREQUENTLY_ASKED_QUESTIONS);
  const [selectedVideoTab, setSelectedVideoTab] = React.useState(VideoTabs.FEATURE);

  const handleChange = (_: React.SyntheticEvent, value: Tabs) => {
    setSelectedTab(value);
  };

  return (
    <Panel onClose={handleViewClose} title="How can we help?">
      <Box px={2}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              value={selectedTab}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabs.map((tab) => (
                <Tab key={tab} value={tab} label={`${startCase(tab)}`} />
              ))}
            </TabList>
          </Box>
          <TabPanel value={Tabs.FREQUENTLY_ASKED_QUESTIONS}>
            {selectedArticle && (
              <Box height={28} pr="28px" textAlign="right">
                <Button size="small" variant="text" onClick={() => setSelectedArticle(null)}>
                  Go back
                </Button>
              </Box>
            )}
            <Box position="relative" zIndex="0">
              <TextField
                placeholder="Search all help and learning resources..."
                fullWidth
                variant="outlined"
                value={searchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading && <CircularProgress size={22} thickness={4} color="primary" />}
                    </InputAdornment>
                  )
                }}
                autoFocus
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setShowSearchList(true);
                }}
              />
              {showSearchList && (
                <Paper
                  style={{
                    overflowY: "auto",
                    maxHeight: "200px",
                    position: "absolute",
                    width: "100%",
                    zIndex: 1
                  }}
                  elevation={2}
                >
                  <ClickAwayListener onClickAway={() => setShowSearchList(false)}>
                    <div>
                      <SearchResultList
                        data={data}
                        onSelect={(article) => {
                          setSearchText(article.data.title);
                          setSelectedArticle(article);
                          setShowSearchList(false);
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                </Paper>
              )}
              {selectedArticle ? (
                <SelectedArticle data={selectedArticle} />
              ) : (
                <>
                  <RelatedArticleList
                    onSelect={(article) => {
                      setSelectedArticle(article);
                      setShowSearchList(false);
                      setSearchText("");
                    }}
                  />
                  <Box mt={2}>
                    <Typography variant="h2" component="h2" fontSize={18} fontWeight={600} mb={2}>
                      Module wise help articles
                    </Typography>
                    <ModuleWiseArticles />
                  </Box>
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={Tabs.MODULES}>
            <ModuleWiseArticles />
          </TabPanel>
          <TabPanel value={Tabs.RELEASE_NOTES}>
            <PreviousReleaseNotes />
          </TabPanel>
          <TabPanel value={Tabs.INSTRUCTION_VIDEOS} sx={{ p: 0 }}>
            <TabContext value={selectedVideoTab}>
              <TabList
                onChange={(_, newValue) => setSelectedVideoTab(newValue)}
                value={selectedTab}
              >
                {Object.keys(VideoTabs).map((vTab) => (
                  <Tab key={vTab} value={vTab} label={`${startCase(vTab)}`} />
                ))}
              </TabList>
              <TabPanel value={VideoTabs.FEATURE}>
                <VideoGrid data={featuresVideos} />
              </TabPanel>
              <TabPanel value={VideoTabs.SUPPORT}>
                <VideoGrid data={supportVideos} />
              </TabPanel>
            </TabContext>
          </TabPanel>
          <TabPanel value={Tabs.CONTACT}>
            <ContactInfo />
          </TabPanel>
        </TabContext>
      </Box>
    </Panel>
  );
};

export default KnowledgeBase;
