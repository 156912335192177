import { Grid } from '@mui/material';
import React from 'react';
import decorators from 'react-treebeard/dist/components/Decorators';

export default class CutomContainer extends decorators.Container {
  render() {
    const {
      style, terminal, onClick, node
    } = this.props;
    style.header.base = {
      color: '#464646',
      display: 'inline-block',
      verticalAlign: 'top',
    };

    return (
      <Grid
        container
        onClick={onClick}
        ref={(ref) => (this.clickableRef = ref)}
      >
        <Grid items className="nodeRef">
          {!terminal ? this.renderToggle() : null}
        </Grid>
        <Grid items xs={11} sm={11} lg={11} md={11}>
          <div className="mrtp6">
            <a>{node.title}</a>
          </div>
        </Grid>
      </Grid>
    );
  }
}
