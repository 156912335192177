import * as React from 'react';
import { ClickAwayListener, Paper, TextField, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import SearchBtn from '@mui/icons-material/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    paper: {
      position: 'relative',
      top: 36,
      right: 0,
      left: 0,
      zIndex: 9999,
      height: '400px',
      overflowY: 'auto',
    },
    query: {
      fontSize: '14px',
      color: '#959595',
    },
  }),
);
interface PropsType {
  searchFn: (query: string) => Promise<Array<any>>;
  itemRenderer: (item: any) => JSX.Element;
  onItemSelect: (item: any) => void;
  onChange: (string) => void;
  inputProps: {};
}
const Search: React.FC<PropsType> = ({
  searchFn,
  itemRenderer,
  placeholder,
  Namevalue,
  onItemSelect,
  onChange,
  inputProps,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [items, setItems] = React.useState([]);
  const timeoutRef = React.useRef(null);
  React.useEffect(() => {
    if (query.length > 2) {
      timeoutRef.current = setTimeout(
        () => {
          searchFn(query).then((results) => {
            setItems(results);
          });
        },
        query.length === 3 ? 0 : 500,
      );
    } else if (query.length === 0) {
      setItems([]);
    }
    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, [query, searchFn]);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box style={{ width: '100%' }}>
        <TextField
          placeholder={placeholder}
          fullWidth
          margin="dense"
          variant="outlined"
          value={Namevalue}
          onClick={() => setOpen(true)}
          onChange={(e) => {
            onChange && onChange(e.currentTarget.value);
            setQuery(e.currentTarget.value);
          }}
          InputProps={
            inputProps
              ? inputProps
              : {
                  endAdornment: <SearchBtn style={{ cursor: 'pointer' }} />,
                }
          }
        />
        {open && query.length && items.length ? (
          <Paper>
            {items.map((item) => (
              <Box
                onClick={() => {
                  setOpen(false);
                  onItemSelect(item);
                }}
              >
                {itemRenderer(item)}
              </Box>
            ))}
          </Paper>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};
export default Search;
