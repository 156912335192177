import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActiveRounded";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNoneRounded";
import {
  Badge,
  Popper,
  IconButton,
  Paper,
  ClickAwayListener,
  MenuList,
  Divider,
  Box,
  MenuItem,
  Button,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Tooltip from "@mui/material/Tooltip";
import { red } from "@mui/material/colors";
import { isEqual, startCase, uniq } from "lodash";
import { getReminders, markAllReminderRead } from "../../actions/reminders";
import NotificationItem from "./NotificationItem";
import { serviceProviderActions } from "../../actions";
import { getClientDetails } from "../../api/client";
import { isReadOnlyUser } from "./SideBar";
import useMobileScreen from "../../hooks/useMobileScreen";
import styles from "./styles.module.css";
import { Client } from "../../interfaces/ClientInterface";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { useAppDispatch } from "../../store/hooks";

export const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: -3,
      top: 5,
      padding: "0 2px",
      background: red[500],
      color: "white"
    }
  })
)(Badge);

interface NotificationsInterface {
  notifications: Array<Record<string, any>>;
  getRemindersAction: () => void;
  resourceCentreId: string | number;
  serviceProviders: Array<ServiceProvider>;
  loadServiceProviders: (id) => void;
  permissionGroup: string;
}

export enum RemindersTypes {
  RemindCustomerOnFollowup = "RemindCustomerOnFollowup",
  CreateBookingForFollowup = "CreateBookingForFollowup",
  PatientBookedFromPublicBooking = "PatientBookedFromPublicBooking",
  LabRecordReady = "LabRecordReady",
  SubscriptionInvoiceCreated = "SubscriptionInvoiceCreated",
  SubscriptionInvoiceDocumentUploaded = "SubscriptionInvoiceDocumentUploaded",
  NotificationToResourceCentre = "NotificationToResourceCentre",
  ExpiringStocks = "ExpiringStocks",
  FinalizedAssessmentLabs = "FinalizedAssessmentLabs"
}

const Notifications: FunctionComponent<NotificationsInterface> = ({
  notifications,
  getRemindersAction,
  resourceCentreId,
  serviceProviders,
  loadServiceProviders,
  permissionGroup
}) => {
  const [clients, setClients] = React.useState<Array<Client>>([]);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isGrouped, setIsGrouped] = React.useState(false);
  const [isSortedByUnread, setIsSortedByUnread] = useState(false);

  const dispatch = useAppDispatch();

  const notificationsRef = React.useRef(notifications);

  if (!isEqual(notificationsRef.current, notifications)) {
    notificationsRef.current = notifications;
  }

  React.useEffect(() => {
    if (!serviceProviders?.length && !!resourceCentreId) loadServiceProviders(resourceCentreId);
    getRemindersAction();
    const interval = setInterval(() => {
      getRemindersAction();
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, []);

  const loadMissingClients = async (): Promise<void> => {
    let missingClientIds = notifications
      .map((notification) => notification.data.clientId)
      .filter(Boolean)
      .filter((clientId) => !clients.some(({ id }) => id === clientId));
    missingClientIds = uniq(missingClientIds);

    if (missingClientIds.length === 0) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const missingClients = (await Promise.all(
        missingClientIds.map((clientId) => getClientDetails(clientId))
      )) as Array<Client>;
      setClients((prevClients) => [...prevClients, ...missingClients]);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching missing clients!");
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (notificationsRef?.current?.length) {
      loadMissingClients();
    } else {
      setIsLoading(false);
    }
  }, [notificationsRef.current]);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const validNotifications = React.useMemo(() => {
    const filteredNotifications = notifications.filter((notification) => {
      if (notification.type === RemindersTypes.LabRecordReady) return true;
      if (notification.type === RemindersTypes.SubscriptionInvoiceCreated) return true;
      if (notification.type === RemindersTypes.SubscriptionInvoiceDocumentUploaded) return true;
      if (notification.type === RemindersTypes.ExpiringStocks) return true;
      return (
        clients.some((client) => client.id === Number(notification.data.clientId)) &&
        serviceProviders.some(
          (serviceProvider) => serviceProvider.id === Number(notification.data.serviceProviderId)
        )
      );
    });
    if (isSortedByUnread) {
      filteredNotifications.sort((a, b) => {
        if (a.done === b.done) {
          return 0;
        }
        if (a.done) {
          return 1;
        }
        return -1;
      });
    }
    return filteredNotifications;
  }, [notificationsRef.current, serviceProviders, clients, isSortedByUnread]);

  const groupedNotifications = isGrouped
    ? validNotifications.reduce((acc, cur) => {
        if (!acc[cur.data.entityName]) {
          // eslint-disable-next-line no-param-reassign
          acc[cur.data.entityName] = [];
        }
        acc[cur.data.entityName].push(cur);
        return acc;
      }, {})
    : {};

  const unreadNotifications = React.useMemo(() => {
    const filteredNotifications = validNotifications.filter((notification) => !notification.done);
    return {
      count: filteredNotifications.length,
      ids: filteredNotifications.map((item) => item.id)
    };
  }, [validNotifications]);

  const isMobileScreen = useMobileScreen(960);
  let iconColor = "inherit";
  if (isMobileScreen) {
    iconColor = "fff";
  } else if (openNotification) {
    iconColor = "00A358";
  }

  return (
    <div>
      {!isReadOnlyUser(permissionGroup) && (
        <Tooltip title="Notifications" aria-label="notifications">
          <IconButton
            className={styles.topButtonDropdown}
            onClick={handleClickNotification}
            size="large"
          >
            <StyledBadge
              invisible={!unreadNotifications.count || isLoading}
              badgeContent={unreadNotifications.count}
              max={20}
            >
              {unreadNotifications.count ? (
                <NotificationsActiveIcon style={{ color: iconColor }} />
              ) : (
                <NotificationsNoneIcon style={{ color: iconColor }} />
              )}
            </StyledBadge>
          </IconButton>
        </Tooltip>
      )}
      <Popper open={Boolean(openNotification)} anchorEl={openNotification} placement="bottom-end">
        <ClickAwayListener onClickAway={handleCloseNotification}>
          <Paper elevation={2} className={styles.notificationBox}>
            <Box px={2} py={1} display="flex" alignItems="center" gap={2}>
              <Typography fontSize={14} fontWeight={600} component="span" flex={1}>
                Notifications
              </Typography>
              <FormControlLabel
                label="Group by modules"
                control={
                  <Checkbox
                    size="small"
                    checked={isGrouped}
                    onChange={() => setIsGrouped(!isGrouped)}
                  />
                }
              />
              <Button
                disabled={!unreadNotifications.count}
                size="small"
                sx={{ textTransform: "capitalize" }}
                onClick={() => dispatch(markAllReminderRead(unreadNotifications.ids))}
              >
                Mark all as read
              </Button>
              <Button
                title="Sort by unread"
                color={isSortedByUnread ? "primary" : "inherit"}
                onClick={() => setIsSortedByUnread(!isSortedByUnread)}
                sx={{ textTransform: "capitalize" }}
              >
                {`Unread (${unreadNotifications.count})`}
              </Button>
            </Box>
            <Divider />
            <MenuList role="menu" className={styles.notificationItems}>
              {!isGrouped
                ? validNotifications.map((notification) => {
                    const serviceProvider = serviceProviders.find(
                      (provider) => provider.id === notification.data.serviceProviderId
                    );
                    const client = clients?.find(
                      (clientI) => clientI.id === Number(notification.data.clientId)
                    );
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        closeNotification={handleCloseNotification}
                        client={client}
                        serviceProvider={serviceProvider}
                      />
                    );
                  })
                : Object.keys(groupedNotifications).map((name) => {
                    const group = groupedNotifications[name];
                    return (
                      <div key={name}>
                        <Typography className={styles.notificationGroupTitle}>
                          {startCase(name)}
                        </Typography>
                        {group.map((item) => {
                          const serviceProvider = serviceProviders.find(
                            (provider) => provider.id === item.data.serviceProviderId
                          );
                          const client = clients?.find(
                            (clientI) => clientI.id === Number(item.data.clientId)
                          );
                          return (
                            <NotificationItem
                              hideBadge
                              key={item.id}
                              notification={item}
                              closeNotification={handleCloseNotification}
                              client={client}
                              serviceProvider={serviceProvider}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
              {validNotifications.length === 0 && (
                <MenuItem className={styles.noHover}>No new notifications</MenuItem>
              )}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const mapStateToProps = ({ userContext, resources, reminders }) => {
  const filteredReminders =
    userContext.mode === "serviceProvider"
      ? reminders.filter((reminder) => reminder.data.serviceProviderId === userContext.user.id)
      : reminders;

  return {
    resourceCentreId: userContext.resourceCentreId,
    notifications: filteredReminders,
    serviceProviders: resources.resourceCentreServiceProviders,
    permissionGroup:
      userContext && userContext.userCreds.userGroups && userContext.userCreds.userGroups[0]
  };
};

const matchDispatchToProps = (dispatch) => ({
  getRemindersAction: () => {
    dispatch(getReminders());
  },
  loadServiceProviders: (id) =>
    dispatch(serviceProviderActions.getResourceCentreServiceProviders({ resourceCentreId: id }))
});

export default connect(mapStateToProps, matchDispatchToProps)(Notifications);
